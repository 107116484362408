import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext
} from 'react';
import styles from './section-recognizer.module.scss';
import isEmpty from 'lodash/isEmpty';
import { AppDeviceSize } from '../../enums/app-device-size';
import { AppStore } from '../../store/app.context';

export interface ISectionRecognizerProps {
  items: IKontentItem[];
}

interface ISections {
  name: string;
  id: string;
  offsetTop: number | undefined;
}

export const SectionRecognizer: FunctionComponent<ISectionRecognizerProps> = ({
  items
}) => {
  const [sections, setSections] = useState<ISections[]>([]);
  const [activeSection, setActiveSection] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { state } = useContext(AppStore);
  const { showActiveDevice } = state;

  useEffect(() => {
    const sectionsArr = addSectionsToState(items);

    setSections(sectionsArr);
  }, [items]);

  useEffect(() => {
    const options = {
      threshold: [0.0, 0.3, 0.5, 0.7]
    };

    const observeSections = (): void => {
      const observer = new IntersectionObserver(activeSectionHandler, options);

      sections.map(section => {
        const elem = document.getElementById(section.id);

        if (elem) {
          return observer.observe(elem);
        }
        return null;
      });
    };

    if (showActiveDevice > AppDeviceSize.TABLET) {
      observeSections();
    }
  }, [sections, showActiveDevice]);

  const addSectionsToState = (sections: IKontentItem[]): ISections[] => {
    const sectionsArr: ISections[] = [];

    sections.map((item): ISections[] => {
      if (
        !isEmpty(item.elements.name) &&
        !isEmpty(item.elements.unique_id.value)
      ) {
        sectionsArr.push({
          name: item.elements.name.value,
          id: item.elements.unique_id.value,
          offsetTop: document
            .getElementById(item.elements.unique_id.value)
            ?.getBoundingClientRect().top
        });
        return sectionsArr;
      }
      return [];
    });
    return sectionsArr.sort((a, b) => a.offsetTop - b.offsetTop);
  };

  const scrollToSectionHandler = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    const elem = event.currentTarget;
    const id = elem.dataset.id;
    const section = id ? document.getElementById(id) : null;

    setActiveSection(id ? id : '');
    setIsOpen(false);
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const activeSectionHandler = (entries): void => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.intersectionRatio >= 0.7) {
          setActiveSection(entry.target.id);
        }
      }
    });
  };

  const toggle = (): void => setIsOpen(prevState => !prevState);

  return (
    <div className={`${styles.wrapper} ${isOpen ? styles.open : styles.hide}`}>
      {showActiveDevice <= AppDeviceSize.TABLET ? (
        <div onClick={toggle} className={styles.mobileToggler}></div>
      ) : null}
      <div className={styles.menuList}>
        {sections.map((section, i) => (
          <div
            data-id={section.id}
            onClick={scrollToSectionHandler}
            className={`${styles.listItemWrapper} ${
              section.id === activeSection ? styles.active : null
            }`}
            key={i}
          >
            {showActiveDevice > AppDeviceSize.TABLET ? (
              <div
                className={styles.dot}
                data-id={section.id}
                onClick={scrollToSectionHandler}
              >
                <i className={`material-icons`}>fiber_manual_record</i>
              </div>
            ) : null}
            <span className={styles.name}>{section.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
