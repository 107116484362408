import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import eventLogo from '../assets/_popup/event-logo.png';
import styles from './withEventPopup.module.scss';
import axios from 'axios';

const LOCAL_STORAGE_KEY = 'eventPopups';

// TODO: move to props
const eventPopupId = 'b7bcc3df-9325-485f-abf1-b7e78030e90b'; // popups should be configurable through cms
const startDate = new Date(2023, 5, 1, 0, 0, 0);
const endDate = new Date(2023, 5, 17, 23, 59, 59);
const targetLocations = ['France'];
//

interface IEventPopupsVisibilityData {
  [key: string]: boolean; // true means hidden
}
const DEFAULT_DATA: IEventPopupsVisibilityData = {};

function getInitialData(): IEventPopupsVisibilityData {
  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (storedData) {
    return JSON.parse(storedData);
  }

  return DEFAULT_DATA;
}

export function withEventPopup(
  Component: FunctionComponent
): FunctionComponent {
  function EventPopupWrapper(props): ReactElement {
    const [visibilityData, setVisibilityData] = useState(DEFAULT_DATA);
    const [userCountry, setUserCountry] = useState<string | null>(null);

    useEffect(() => {
      getInitialData();
    }, []);

    const isVisible = useMemo(() => {
      return !visibilityData[eventPopupId] ?? true;
    }, [visibilityData]);

    const getUserLocation = async (): Promise<void> => {
      const geoAPIs = [
        `https://api.ipbase.com/v1/json/?apikey=668a6c40-4936-11ec-abb7-a5f241067665`,
        `https://ipapi.co/json/`
      ];
      for (let i = 0; i < geoAPIs.length; i++) {
        let userLocation: string | undefined;
        await axios
          .get(geoAPIs[i])
          .then(({ data }) => {
            userLocation = data.country_code;
            data.country_name
              ? setUserCountry(data.country_name)
              : setUserCountry(null);
          })
          .catch(error => {
            console.error('::Error::', error.message);
          });
        if (userLocation) break;
      }
    };

    useEffect(() => {
      getUserLocation();
    }, []);

    const shouldDisplay = useMemo(() => {
      const now = new Date();

      if (userCountry) {
        return (
          now > startDate &&
          now < endDate &&
          targetLocations.includes(userCountry)
        );
      }

      return false;
    }, [userCountry]);

    const closePopup = useCallback(() => {
      setVisibilityData(prevVisibilityData => ({
        ...prevVisibilityData,
        [eventPopupId]: true
      }));
    }, [eventPopupId]);

    useEffect(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(visibilityData));
    }, [visibilityData]);

    if (props.path !== '/' || !shouldDisplay || !isVisible) {
      return <Component {...props} />;
    }

    return (
      <>
        <Component {...props} />
        <div className={styles.overlay}>
          <div className={styles.popup}>
            <div role="button" className={styles.close} onClick={closePopup}>
              <i className="material-icons">close</i>
            </div>
            <div className={styles.content}>
              <img src={eventLogo} alt="Viva Technology logo" />
              <p>
                Rejoignez-nous à Paris du 14 au 17 juin, à la conférence
                Vivatech Technology ! Nous vous attendrons, avec  L’Agence
                Polonaise pour l'Investissement et le Commerce (PAIH), sur le
                stand numéro B25. Venez discuter avec nous et faire le test de
                nos solutions mettant en oeuvre la technologie de réalité mixte.
                Au plaisir de vous rencontrer !
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return EventPopupWrapper;
}
