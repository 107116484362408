import { graphql, PageProps } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Footer } from '../components/footer/footer';
import { Navbar } from '../components/navbar/navbar';
import RichText from '../components/rich-text/rich-text';
import { SectionRecognizer } from '../components/section-recognizer/section-recognizer';
import { SeoComponent } from '../components/seo-component/seo-component';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { withCookieBar } from '../hoc/withCookieBar';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.scss';
import '../styles/reset.css';
import { pageDataResolver } from '../utils/pageDataResolver';
import { filterById } from '../utils/utils';
import { getDataFromURL } from '../utils/getDataFromURL';
import { withEventPopup } from '../hoc/withEventPopup';

export interface IElevatoListItem {
  elevatoId: number;
  title: string;
  jobLocation: string;
}

interface IData {
  allKontentItemBlogArticle: {
    nodes: IBlogArticle[];
  };
  kontentItemPage: IPage;
  cookieSection: ICookieData;
  allElevatoApiItems: {
    nodes: IElevatoListItem[];
  };
}

type PagePageProps = PageProps<IData, IPageContext>;

const Page: React.FunctionComponent<PagePageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          origin: props.location.origin,
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.location.href || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);
    handleResize();
    handleChangePageState();

    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    props.data.cookieSection.elements.button_cta.value,
    props.data.cookieSection.elements.text.value,
    props.location,
    props.location.state,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    state.showActiveDevice,
    state.showPageState.currentUri
  ]);

  useEffect(() => {
    getDataFromURL();
  }, []);

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);
  const filteredArticles = useMemo(
    () => filterById(props.data.allKontentItemBlogArticle.nodes),
    [props.data.allKontentItemBlogArticle.nodes]
  );
  const jobOffersElevato = useMemo(() => props.data.allElevatoApiItems.nodes, [
    props.data.allElevatoApiItems.nodes
  ]);

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <RichText
        {...props.data.kontentItemPage.elements.body}
        latestArticles={filteredArticles && filteredArticles}
        jobOffersElevato={jobOffersElevato}
      />
      {pageData.footerData && <Footer {...pageData.footerData} />}
      {props.pageContext.template === 'staticpage' ? (
        <SectionRecognizer
          items={props.data.kontentItemPage.elements.body.modular_content}
        />
      ) : null}
    </>
  );
};

export const Head = (props: PagePageProps) => {
  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return <SeoComponent {...pageData.seoData} />;
};

export default withAppStore(
  withEventPopup(withCookieBar(React.memo(Page as FunctionComponent<{}>)))
);

export const query = graphql`
  query($pageId: String, $pageLanguage: String, $category: [String]) {
    kontentItemPage(
      system: { id: { eq: $pageId }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentPageComponent
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    allKontentItemBlogArticle(
      filter: {
        preferred_language: { eq: $pageLanguage }
        elements: {
          blog_category: {
            value: { elemMatch: { system: { codename: { in: $category } } } }
          }
        }
      }
      sort: { fields: elements___publication_date___value, order: DESC }
      limit: 5
    ) {
      nodes {
        ...FragmentBlogArticleListItem
      }
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
    allElevatoApiItems: allElevatoApiItemsEn {
      nodes {
        elevatoId
        title
        jobLocation
      }
    }
  }
`;
